import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Heading } from "../common/Heading"
import React from "react"
import Slider from "react-slick"
import { team } from "../data/dummydata"

// import FormatQuoteIcon from "@mui/icons-material/FormatQuote"





export const Team = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
  }
  return (
    <>
      <section className='team hero'>
        <div className='container'>
          <Heading title='MEET THE TEAM' />
          <Slider {...settings}>
            {team.map((val, key) => (
              <div key={key} className='box'>
                <div className='img' data-aos='zoom-out-right'>
                  <img src={val.image} alt='' />
                </div>
                <h3 data-aos='zoom-out-left'>{val.name}</h3>
                <label data-aos='zoom-out'>{val.post}</label>
                <p data-aos='zoom-out-down'>{val.text}</p>
                
                
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  )
}
