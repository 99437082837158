import { Heading } from "../common/Heading"
import React from "react"
import { Team } from "./Team"
import { about } from "../data/dummydata"

export const About = () => {
  return (
    <>
      <section className='about'>
        <div className='container flex' style={{alignItems: 'center'}}>
          {about.map((val, key) => (
            <React.Fragment key={key}>
              <div className='left about-img' data-aos='fade-down-right'>
                <img src={val.cover} alt='' style={{maxWidth: '100%', borderRadius: '10px'}} />
              </div>
              <div className='right' data-aos='fade-down-left'>
                <Heading title='About US' />
                <p>{val.desc}</p>
                <p>{val.desc1}</p>
                <Heading title='Why Choose Us ' />
                <p>{val.desc2}</p>
                <p>{val.desc3}</p>
                <p>{val.desc4}</p>
                <p>{val.desc5}</p>
                <p>{val.desc6}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
        
      </section>
      <Team />
    </>
  )
}
