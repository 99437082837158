import { Route, BrowserRouter as Router, Switch } from "react-router-dom"

import { About } from "./About"
import { Blog } from "./Blog"
import { Contact } from "./Contact"
import Footer from "../common/Footer"
import { Header } from "../common/Header"
import { Home } from "../home/Home"
// import Logos from "./Logos"
import { Portfolio } from "./Portfolio"
import React from "react"
import { Services } from "./Services"
import { Team } from "./Team"

export const Pages = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/team' component={Team} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/contact' component={Contact} />
          {/* <Route exact path='/logos' component={Logos} /> */}
        </Switch>
        <Footer />
      </Router>
    </>
  )
}
