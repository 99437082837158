import { AddLocationAltOutlined, BarChart, CloudOutlined, Code, CropRotate, EmailOutlined, Facebook, FavoriteBorder, Instagram, LinkedIn, PersonOutlined, PhoneIphone, PieChart, Public, Settings, ViewInAr } from "@mui/icons-material"

export const navlink = [
  {
    url: "/",
    text: "Home",
  },
  {
    url: "/about",
    text: "About",
  },
  {
    url: "/services",
    text: "Services",
  },
  {
    url: "/portfolio",
    text: "Portfolio",
  },
  {
    url: "/contact",
    text: "Contact",
  },
]
export const home = [
  {
    // text: "HELLO I'M",
    name: "AKITO",
    post: "MARKETING",
    design: "PR",
    desc: "Overview Akito  Marketing  Management & PR is a dynamic and innovative agency specializing in strategic marketing and public relations solutions. With a team of creative minds and industry experts, we are committed to delivering exceptional results and helping our clients achieve their business goals.",
  },
]
export const about = [
  {
    desc: "Overview Akito  Marketing  Management & PR is a dynamic and innovative agency specializing in strategic marketing and public relations solutions. With a team of creative minds and industry experts, we are committed to delivering exceptional results and helping our clients achieve their business goals.",
    desc1: "Our mission is to empower businesses and brands to reach their full potential through tailored marketing strategies and impactful public relations campaigns. We strive to be a trusted partner in our clients' success, providing innovative solutions that drive growth and visibility.",
    desc2: "Experienced Team: Our team consists of seasoned professionals with years of experience in marketing, PR, and creative design.",
    desc3: "Customized Solutions: We understand that every business is unique, and we tailor our strategies to meet specific goals an objectives.",
    desc4: "Innovative Approach: We stay ahead of industry trends and leverage cutting-edge tools and technologies to deliver  innovative solutions.",
    desc5: "Results-Driven: Our focus is on delivering measurable results and tangible outcomes that contribute to our clients' success.",
    desc6: "Client-Centric: We prioritize clear communication, transparency, and collaboration, ensuring a seamless experience for our  clients.",
    cover: "./images/team.jpg",
  },
]
export const services = [
  {
    id: 1,
    icon: <Settings />,
    title: "Brand Development & Positioning",
    desc: "This involves creating a unique image and identity for a brand in the market to make the brand easily recognizable and distinct from competitors.",

  },
  {
    id: 2,
    icon: <CropRotate />,
    title: "Digital Marketing Strategies",
    desc: "These are plans to promote products or services using digital channels to reach and engage target audiences online.",
  },
  {
    id: 3,
    icon: <ViewInAr />,
    title: "Social Media Management",
    desc: "Managing a brand's presence on social media platforms to build a community, engage with followers, and increase brand visibility.",
  },
  {
    id: 4,
    icon: <PieChart />,
    title: "Content Creation & Copywriting",
    desc: "Producing written and visual content for marketing purposes to inform, entertain, and persuade audiences.",
  },
  {
    id: 5,
    icon: <Code />,
    title: "Campaign Planning & Execution",
    desc: "Designing and implementing marketing campaigns to achieve specific marketing goals, like increasing brand awareness or driving sales.",
  },
  {
    id: 6,
    icon: <BarChart />,
    title: "Graphic Design & Branding",
    desc: "Creating visual content to communicate messages to enhance the brand's identity and appeal.",
  },
  {
    id: 7,
    icon: <BarChart />,
    title: "Website Development & Optimization",
    desc: "Building and improving websites for better performance to ensure the website is user-friendly and fast.",
  },
  {
    id: 8,
    icon: <BarChart />,
    title: "Video Production & Animation",
    desc: "Creating video content and animations for marketing to capture attention and convey messages effectively.",
  },
]
export const project = [
  {
    id: 1,
    icon: <CloudOutlined />,
    num: "89",
    title: "HAPPY CLIENTS",
  },
  {
    id: 2,
    icon: <FavoriteBorder />,
    num: "31",
    title: "PROJECTS COMPLEATED",
  },
  {
    id: 3,
    icon: <Public />,
    num: "108",
    title: "FILES DOWNLOADED",
  },
  {
    id: 4,
    icon: <PersonOutlined />,
    num: "1446",
    title: "LIENS OF CODE",
  },
]
export const portfolio = [
  {
    id: 1,
    cover: "../images/s-img/deve/Psd.jpg",
    name: "website development",
    category: "development",
    title: "Alkady to food production and marketing.",
    url: "https://al-kady.com"
  },
  {
    id: 2,
    cover: "../images/s-img/GD/1.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "syriatel",
  },
  {
    id: 3,
    cover: "../images/s-img/GD/2.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "syrian climate",
  },
  {
    id: 4,
    cover: "../images/s-img/GD/4.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "مؤسسة سيرينا لتنظيم الرحلات الجوية",
  },
  {
    id: 5,
    cover: "../images/s-img/GD/5.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "اليوم العالمي للشاي",
  },
  {
    id: 6,
    cover: "../images/s-img/GD/6.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "القرد بعين أمه غزال",
  },
  {
    id: 7,
    cover: "../images/s-img/GD/7.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "بصمة أمل على جدار ألم",
  },
  {
    id: 8,
    cover: "../images/s-img/GD/8.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "تدريب على قول كلمة لاء",
  },
  {
    id: 9,
    cover: "../images/s-img/GD/9.jpg",
    name: "Graphic Design",
    category: "Graphic Design",
    title: "من وين اجت كلمة مصاري",
  },
  {
    id: 10,
    cover: "../images/s-img/social/2.jpg",
    name: "Branding",
    category: "Branding",
    title: "HYPER",
  },
  {
    id: 11,
    cover: "../images/s-img/social/3.jpg",
    name: "Branding",
    category: "Branding",
    title: "MELODI",
  },
  {
    id: 12,
    cover: "../images/s-img/social/4.jpg",
    name: "Branding",
    category: "Branding",
    title: "Diva",
  },
  {
    id: 13,
    cover: "../images/s-img/social/5.jpg",
    name: "Branding",
    category: "Branding",
    title: "Youth Booster",
  },
  {
    id: 14,
    cover: "../images/s-img/social/6.jpg",
    name: "Branding",
    category: "Branding",
    title: "Poke One",
  },
  {
    id: 15,
    cover: "../images/s-img/podcast/1.jpg",
    name: "Video Production",
    category: "Video Production",
    // title: "HYPER",
  },
  {
    id: 16,
    cover: "../images/s-img/podcast/2.jpg",
    name: "Video Production",
    category: "Video Production",
    // title: "HYPER",
  },
  {
    id: 17,
    cover: "../images/s-img/podcast/3.jpg",
    name: "Video Production",
    category: "Video Production",
    // title: "HYPER",
  },
  
]
export const team = [
  {
    id: 1,
    image: "./images/team/husam.JPG",
    name: "Hussam moussa",
    post: "Ceo and founder",
  },
  {
    id: 2,
    image: "./images/team/suliman.jpg",
    name: "Suliman Wannous",
    post: "Front End Developer",
  },
  {
    id: 3,
    image: "./images/team/ali.jpg",
    name: "Ali Mohsen",
    post: "Back End Developer",
  },
  {
    id: 4,
    image: "./images/team/h.jpg",
    name: "Hiedy Riyan",
    post: "Producer",
  },
]
export const blog = [
  {
    id: 1,
    title: "Master These Awesome",
    date: "Jun 27, 2022",
    author: "Dorian Gray",
    desc: "Lorem Ipsum has been standard. Lorem Ipsum is simply text of the printing and typesetting industry. Lorem Ipsum has been",
    cover: "./images/blog/b1.png",
  },
  {
    id: 2,
    title: "Best Design Items to Appeal",
    date: "Jun 27, 2022",
    author: "Dorian Gray",
    desc: "Lorem Ipsum has been standard. Lorem Ipsum is simply text of the printing and typesetting industry. Lorem Ipsum has been",
    cover: "./images/blog/b2.png",
  },
  {
    id: 3,
    title: "The 20 Best Lightroom Presets",
    date: "Jun 27, 2022",
    author: "Dorian Gray",
    desc: "Lorem Ipsum has been standard. Lorem Ipsum is simply text of the printing and typesetting industry. Lorem Ipsum has been",
    cover: "./images/blog/b3.png",
  },
]
export const contact = [
  {
    icon: <AddLocationAltOutlined />,
    text1: "United Arab Emirates",
    text2: "Dubai",
  },
  {
    icon: <PhoneIphone />,
    text1: "+971505715254",
  },
  {
    icon: <EmailOutlined />,
    text1: "Ceo@akito.ae",
    text2: "Management@akito.ae",
  },
]

export const company = [
  {
    id: 1,
    image: "./images/s-img/company/1.png",
  },
  {
    id: 2,
    image: "./images/s-img/company/2.png",
  },
  {
    id: 3,
    image: "./images/s-img/company/3.png",
  },
  {
    id: 4,
    image: "./images/s-img/company/5.png",
  },
  {
    id: 5,
    image: "./images/s-img/company/6.png",
  },
]
export const social = [
  {
    icon: <Facebook />,
    url: 'https://www.facebook.com/Akito.llc.hm?mibextid=LQQJ4d'
  },
  {
    icon: <Instagram />,
    url: 'https://www.instagram.com/akitollc?igsh=MzdsMzllZnBjMDM='

  },
  {
    icon: <LinkedIn />,
    url: 'https://www.linkedin.com/company/akito-marketing-management-and-public-relations/'
  },
]
