import React, { useState } from "react";

import { Heading } from "../common/Heading";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { portfolio } from "../data/dummydata";

const allCategory = ["all", ...new Set(portfolio.map((item) => item.category))];

export const Portfolio = () => {
  const [list, setLists] = useState(portfolio);
  const [activeCategory, setActiveCategory] = useState("all");

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setLists(portfolio);
    } else {
      const newItems = portfolio.filter((item) => item.category === category);
      setLists(newItems);
    }
  };

  return (
    <>
      <article>
        <div className='container'>
          <Heading title='Portfolio' />
          <div className='catButton'>
            {allCategory.map((category, key) => (
              <button
                key={key}
                className={`primaryBtn ${activeCategory === category ? "active" : ""}`}
                onClick={() => filterItems(category)}
                data-aos='zoom-out-down'
              >
                {category}
              </button>
            ))}
          </div>
          <div className='content grid3'>
            {list.map((item, key) => (
              <div key={key} className='box' data-aos='fade-up'>
                <div className='img'>
                  <img src={item.cover} alt='' />
                </div>
                <div className='overlay'>
                  <h3>{item.title}</h3>
                  <span>{item.name}</span>
                  {item.url ? (
                    <a href={item.url} target='_blank' rel='noreferrer' style={{ color: '#FFF' }}>
                      <VisibilityOutlinedIcon />
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </article>
    </>
  );
};
