import React from "react"
import { social } from "../data/dummydata"

const Footer = () => {
  return (
    <>
      <footer>
        {social.map((item, key) => (
          <a key={key} href={item.url} target="_blank" rel="noreferrer">
            <i data-aos='zoom-in'>{item.icon}</i>
          </a>
        ))}
        <div style={{marginBottom: '50px'}}></div>
        {/* <p data-aos='zoom-in'>All Right Resceved 2018</p> */}
      </footer>
    </>
  )
}

export default Footer
